import React from 'react';

const RequestQuote = () => {
    return (
        <section className="callto-action-area">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="callto-action-wrap col-lg-12 relative section-gap">
                        <div className="content">
                            <h1>
                                Looking for a <br />
                                quality and affordable <br />interior service or any services?
                            </h1>
                            <p className="mx-auto">
                                Feel free to request a quote TODAY!
                            </p>
                            <a target="_blank" href="mailto:contact@newinteriors.co.in" className="primary-btn text-uppercase"
                            >Request quote now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default RequestQuote;
