import React from 'react';
import HalfBanner from "../components/halfBanner";
import Layout from '../components/layout';
import Seo from "../components/seo";
import Features from "../components/features";
import RequestQuote from "../components/quote";
import ContactForm from "../components/contactForm";

const Contact = () => {
    return (
        <Layout>
            <Seo title="Contact" />
            <HalfBanner
                title="Contact"
            />
            {/* <Features /> */}
            <RequestQuote />
            <ContactForm />
        </Layout>

    );
};

export default Contact;